
.row {
  position: relative;
}

.more-button {
  width: 20px;
}

.resolved {
  opacity: 0.6;
}
