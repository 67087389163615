
form {
  min-height: 85vh;
}

.drawer-footer {
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  border-top: 1px solid #e8e8e8;
  padding: 10px 0px;
  text-align: right;
  background: #fff;
}

.ivu-form-item {
  .ivu-form-item-label {
    order: 2;
  }
}
