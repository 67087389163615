
@import 'ui/misc/styles/breakpoints.scss';

.link-header {
  min-height: 30px;
  max-height: 30px;
  overflow: hidden;
}

.ivu-affix .link-header {
  border-bottom: 1px solid #dee2e6;

  a {
    overflow: hidden;
  }
}

@media (max-width: $breakpoint-md) {
  .link-header {
    overflow: hidden;
    min-height: 55px;
    max-height: 55px;
  }
}
@media (max-width: $breakpoint-se) {
  .link-header {
    min-height: 45px;
    max-height: 45px;
  }
}
