$primary-color: #6190e8;
$link-color: #6190e8;
$body-background: #f9fbfc;
$layout-body-background: #f9fbfc;
$font-size-base: 16px;
$font-size-small: 14px;
$font-size-large: $font-size-base;

$spin-dot-size-small: 22px;
$spin-dot-size: 30px;
$spin-dot-size-large: 42px;

@import 'view3/src/styles/custom';

@import 'view3/src/styles/mixins/index';
@import 'view3/src/styles/common/base';
@import 'view3/src/styles/common/layout';
@import 'view3/src/styles/common/article';
@import 'view3/src/styles/common/icons';
@import 'view3/src/styles/animation/index';

@import 'view3/src/styles/components/button';
@import 'view3/src/styles/components/switch';
@import 'view3/src/styles/components/select';
@import 'view3/src/styles/components/select-dropdown';
@import 'view3/src/styles/components/modal';
@import 'view3/src/styles/components/menu';
@import 'view3/src/styles/components/card';
@import 'view3/src/styles/components/input';
@import 'view3/src/styles/components/form';
@import 'view3/src/styles/components/cell';
@import 'view3/src/styles/components/spin';
@import 'view3/src/styles/components/message';
@import 'view3/src/styles/components/checkbox';
@import 'view3/src/styles/components/radio';
@import 'view3/src/styles/components/tooltip';
@import 'view3/src/styles/components/poptip';
@import 'view3/src/styles/components/affix';
@import 'view3/src/styles/components/tag';
@import 'view3/src/styles/components/drawer';
@import 'view3/src/styles/components/divider';
@import 'view3/src/styles/components/collapse';

.#{$spin-prefix-cls} {
  &-fix {
    background-color: rgba(255, 255, 255, 0.4);
  }
}

html,
body {
  height: 100%;
}

.h1 {
  font-size: 26px !important;
}

.h2 {
  font-size: 20px !important;
}

.h3 {
  font-size: 18px !important;
}

.h4 {
  font-size: 16px !important;
}

.h5 {
  font-size: 14px !important;
}

.h6 {
  font-size: 12px !important;
}

.pointer-cursor {
  cursor: pointer;
}

.ivu-tag,
.ivu-tag-text {
  cursor: pointer;
}

.drawer-no-bottom-padding {
  .ivu-drawer-body {
    padding-bottom: 0;
  }
}

.circle-checkbox {
  .ivu-checkbox-inner::after {
    height: 12px !important;
    width: 9px !important;
  }

  .ivu-checkbox-inner {
    height: 22px;
    width: 22px;
  }

  .ivu-checkbox-inner {
    border-radius: 100%;
  }
}

.ivu-divider-inner-text {
  color: $text-color;
}

@media (max-width: 900px) {
  .ivu-drawer {
    width: 100% !important;
  }
}

.ivu-collapse {
  border: 0 !important;

  .ivu-collapse-content {
    padding: 0 !important;
  }

  .ivu-collapse-header {
    padding-left: 0 !important;

    i {
      margin: 0 !important;
    }
  }
}

.ivu-tooltip-inner {
  text-align: center;
  white-space: pre;
}

.ivu-btn-small {
  height: 28px;
}

.text-break-all {
  word-break: break-all;
}
