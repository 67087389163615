
@import 'ui/misc/styles/breakpoints';

.ivu-layout-header {
  .ivu-menu-item {
    font-size: 17px;
    display: flex;
    align-items: center;
  }

  .ivu-icon.ivu-menu-submenu-title-icon:before {
    content: "\f330" !important;
    font-size: 20px;
  }

  @media (max-width: $breakpoint-sm) {
    .ivu-menu-item {
      font-size: 16px;
    }

    .ivu-icon.ivu-menu-submenu-title-icon:before {
      font-size: 16px;
    }

    .ivu-menu-horizontal .ivu-menu-item, .ivu-menu-horizontal .ivu-menu-submenu {
      padding: 0 10px;
    }
  }
}
